import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { api } from "../../../../services";
import { Button } from "primereact/button";

export default function NumberPlateDropdown(props) {
  const { onChangeNumberPlate, selectedNumberPlate, onClick, visible } = props;
  const [allNumberPlate, setAllNumberPlate] = useState([]);

  const getAllNumberPlate = async () => {
    const { data = [], error } = await api.numberPlate.getAllNumberPlate();
    setAllNumberPlate(data);
  };

  useEffect(() => {
    getAllNumberPlate();
  }, [visible]);

  return (
    <>
      <label className="block py-1">Plaka Seç</label>
      <div className="flex">
        <Dropdown
          filter
          showClear
          filterBy="name"
          emptyMessage="Plaka Bulunamadı"
          className="w-full my-1 mr-4"
          optionLabel="name"
          value={allNumberPlate?.find((item) => item?.id == selectedNumberPlate?.id)}
          options={allNumberPlate}
          onChange={onChangeNumberPlate}
          placeholder="Plaka Seç"
        />
        <Button icon="pi pi-plus" className="p-button-success px-4" onClick={onClick} />
      </div>
    </>
  );
}
